<template>
<div>
  <titre-block-edit :block="blc" :anchor="false"/>
  <v-row>
    <v-col>
      <v-switch
          dense
          v-model="obj.gridMode"
          label="Mode grille"
      />
    </v-col>
    <v-col>
      <v-switch
          dense
          v-model="obj.transparent"
          label="Transparent"
      />
    </v-col>
  </v-row>


  <records-field
      label="Pages à afficher dans la liste"
      placeholder="Rechercher..."
      :field="obj.pages"
      :record-types="['page','post','formation','metier','projet','portrait']"
  />
</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import TitreBlockEdit from "@/Models/blocks/texte/titre-block-edit";
import RecordsField from "@/ee09/db-ui/fields/records-field";

export default {
  name: "carousel-pages-block-edit",
  components: {RecordsField, TitreBlockEdit},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CarouselPagesBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
