<template>
<div class="mt-3">

  <v-card elevation="1" tile>
      <v-system-bar >
        <v-spacer/>
        <v-icon
            v-if="listItems.items.length>1"
            @click="listItems.moveUp(item)">
          mdi-arrow-up
        </v-icon>
        <v-icon
            v-if="listItems.items.length>1"
            @click="listItems.moveDown(item)">
          mdi-arrow-down
        </v-icon>
        <span class="mr-5"/>
        <v-icon @click="listItems.remove(item)">
          mdi-trash-can
        </v-icon>
      </v-system-bar>


    <v-card-text>
      <slot/>
    </v-card-text>

  </v-card>

  <v-system-bar color="transparent" class="my-3" v-if="actionAdd">
    <v-spacer/>
    <v-icon @click="listItems.insertAfter(null,item)">
      mdi-plus
    </v-icon>
    <v-spacer/>
  </v-system-bar>

</div>
</template>
<script>
export default {
  name: "list-items-organizer-item",
  props:{
    list:{
      type:Object
    },
    item:{
      type:Object
    },
    actionAdd:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    /**
     *
     * @return {ItemList}
     */
    listItems(){
      return this.list
    },
    /**
     *
     * @return {EE09Item}
     */
    it(){
      return this.it
    }
  }
}
</script>

<style scoped>

</style>