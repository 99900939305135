<template>
<div class="list-items-organizer">
  <v-system-bar color="transparent" v-if="actionAdd">
    <v-spacer></v-spacer>
    <v-icon small icon @click="list.prepend()">mdi-plus</v-icon>
    <v-spacer></v-spacer>
  </v-system-bar>
  <slot/>
</div>
</template>

<script>
export default {
  name: "list-items-organizer",
  props:{
    list:{
      type:Object
    },
    actionAdd:{
      type:Boolean,
      default:true
    }
  },
}
</script>

<style lang="less">
.list-items-organizer{
  //outline: 1px dashed red;
}
</style>