<template>
  <div>
    <v-text-field
        filled
        label="email"
        v-model="recordObject.email"
        autocomplete="new-password"
        placeholder="user@domain.com"
        :rules="emailRules"
    />
    <v-text-field
        filled
        autocomplete="new-password"
        label="mot de passe"
        v-model="recordObject.clearPwd"
        type="password"
        :rules="passwordRules"
    />
    <v-switch v-model="recordObject.isadminTemp"
              label="Administrateur"/>
    <v-switch v-model="recordObject.isdevTemp"
              label="Développeur"/>
    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import UserModel from "@/Models/UserModel";

export default {
  name: "user-edit",
  components: { CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {UserModel}
     */
    recordObject(){
      return this.record;
    },
    passwordRules(){
      return UserModel.passwordRules();
    },
    emailRules(){
      return UserModel.emailRules();
    }
  }
}
</script>

