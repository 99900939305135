<template>
<div class="image-field">
  <v-system-bar lights-out>
    <field-label v-if="label">{{label}}</field-label>
    <v-spacer></v-spacer>
    <v-btn x-small text v-if="f && f.isOk" @click="showDetails=!showDetails">
          <v-icon left x-small >
            mdi-{{showDetails?'minus':'plus'}}
         </v-icon>
      paramètres
    </v-btn>
  </v-system-bar>

  <record-list-item v-if="readOnly"
                    class="px-0"
                    :record="f.record"/>

  <v-autocomplete
                  v-else
                  v-model="f.record"
                  :items="$db.recordListImages"
                  :filter="customFilter"
                  filled
                  item-text="name"
                  return-object
  >
    <template v-slot:prepend-inner>
      <!-- permet par exemple de placer un drapeau ;) -->
      <slot name="prepend"></slot>
    </template>
    <template v-slot:append>
      <slot name="action"></slot>
      <div @mousedown.stop="" v-if="uploader">
        <record-create-btn
            small
            record-type="filerecord"
            file-accept="image/*"
            @set-record-file="setFile"
            :progress="progress"
            :status="status"
        />
      </div>
    </template>
    <!-- le record sélectionné -->
    <template v-slot:selection="data">
      <record-list-item :record="data.item" class="ml-n3"/>
    </template>

    <!-- Liste des images -->
    <template v-slot:item="data">
      <record-list-item-avatar :record="data.item"/>
      <record-list-item-content :record="data.item"/>
      <record-list-item-action :record="data.item"/>
    </template>
  </v-autocomplete>

  <v-row v-if="showDetails" class="mb-5">
    <v-col cols="12">
      <v-text-field label="Attribut ALT" v-model="f.alt" hide-details dense/>
    </v-col>
    <v-col cols="12">
      <v-text-field label="Title" v-model="f.title" hide-details dense/>
    </v-col>
    <!--
    <v-col cols="6">
      <v-text-field label="x" v-model="f.x" type="number" min="0" max="1" step="0.1" hide-details/>
    </v-col>
    <v-col cols="6">
      <v-text-field label="y" v-model="f.y" type="number" min="0" max="1" step="0.1" hide-details/>
    </v-col>
    -->
    <v-col v-if="f.href" cols="12">
      <file-image-visualizer
          :image-field="f"
          :src="f.resize(1024,1024)"
      />
    </v-col>
  </v-row>
</div>
</template>

<script>
import RecordCreateBtn from "../records/record-create-btn";
import RecordListItem from "../records/record-list-item";
import FieldLabel from "../fields/field-label";
import RecordListItemAction from "../records/list-item/record-list-item-action";
import RecordListItemContent from "../records/list-item/record-list-item-content";
import RecordListItemAvatar from "../records/list-item/record-list-item-avatar";
import FileImageVisualizer from "../components/file-image-visualizer";

export default {
  name: "image-field",
  components: {
    FileImageVisualizer,
    RecordListItemAvatar,
    RecordListItemContent,
    RecordListItemAction,
    FieldLabel,
    RecordListItem,
    RecordCreateBtn,
  },
  props:{
    label:{
      type:String
    },
    field:{
      type:Object
    },
    uploader:{
      type:Boolean,
      default:true
    },
    /**
     * Si true alors il n'est pas possible de changer l'image (mais il est possible de changer alt, title et x,y)
     */
    readOnly:{
      type:Boolean
    }
  },
  data(){
    return{
      showDetails:false,
      progress:0,
      status:''
    }
  },
  methods:{
    /**
     *
     * @param {event} e
     * @param {FileRecord} record
     */
    setFile(e,record){
      let me = this;
      if(record){
        me.field.record=record;
      }
    },
    customFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1;
    }
  },
  computed:{
    /**
     * Le champ image
     * @return {ImageField}
     */
    f(){
      return this.field;
    },
  }
}
</script>
