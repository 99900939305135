<template>
<div>
  <v-text-field

      label="Titre première liste"
      v-model="obj.titre1"
      filled
      placeholder="Enseignement général"
  />
  <list-items-organizer :list="obj.liste1">
    <list-items-organizer-item
        v-for="item of obj.liste1.items"
        :list="obj.liste1"
        :item="item"
        :key="item.uid">
      <v-text-field
          v-model="item.titre"
          dense
          label="Titre"
      />
      <rich-text-field v-model="item.texte"/>
    </list-items-organizer-item>
  </list-items-organizer>

  <v-text-field
      label="Titre deuxième liste"
      v-model="obj.titre2"
      filled
      placeholder="Enseignement artistique"
  />
  <list-items-organizer :list="obj.liste2">
    <list-items-organizer-item
        v-for="item of obj.liste2.items"
        :list="obj.liste2"
        :item="item"
        :key="item.uid">
      <v-text-field
          v-model="item.titre"
          dense
          label="Titre"
      />
      <rich-text-field v-model="item.texte"/>
    </list-items-organizer-item>
  </list-items-organizer>


</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";

export default {
  name: "textes-accordeons-block-edit",
  components: { ListItemsOrganizerItem, ListItemsOrganizer, RichTextField},
  mixins:[BlockMixin],
  data () {
    return {}
  },
  mounted() {
    if(this.obj.liste1.items.length===0){
      this.obj.liste1.prepend();
    }
    if(this.obj.liste2.items.length===0){
      this.obj.liste2.prepend();
    }
  },
  computed:{
    /**
     *
     * @return {TextesAccordeonsBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
