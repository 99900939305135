<template>
<div>
  <v-text-field
      filled
      :label="getLabel('name','Nom')"
      :placeholder="getPlaceholder('name','Nom de la page ici...')"
      v-model="recordObject.name"
  />
  <record-field
      :label="getLabel('parent','Page parente')"
      :placeholder="getLabel('parent','Choisissez')"
      :field="recordObject.parent"
      :record-types="[
          'page'
          ]"
  />

  <image-field label="Miniature"
               :field="recordObject.thumbnail"/>
  <image-field label="Image du poster"
               :field="recordObject.poster"/>
  <file-field label="Video du poster"
              file-accept="video/*"
              :field="recordObject.videoposter"
  />

</div>
</template>

<script>
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import RecordField from "@/ee09/db-ui/fields/record-field";
import ImageField from "@/ee09/db-ui/fields/image-field";
import FileField from "@/ee09/db-ui/fields/file-field";

export default {
  name: "page-edit-fields",
  components: {
    FileField,
    ImageField,
    RecordField},
  mixins:[RecordEditMixin],
  props:{
    labels:{
      type:Object
    }
  },
  computed:{
  /**
   *
   * @return {PageModel}
   */
  recordObject(){
    return this.record;
  },
    labelName(){
      if(this.labels && this.labels.name && this.labels.name.label){
        return this.labels.name.label
      }else{
        return "Nom"
      }
    },

  },
  methods:{
    /**
     * @param {String} fieldName
     * @param {String} defaultLabel
     */
    getLabel(fieldName,defaultLabel){
      return this.getProp("label",fieldName,defaultLabel);
    },
    /**
     * @param {String} fieldName
     * @param {String} defaultLabel
     */
    getPlaceholder(fieldName,defaultLabel){
      return this.getProp("placeholder",fieldName,defaultLabel);
    },
    getProp(prop,fieldName,defaultVal){
      if(this.labels && this.labels[fieldName] && this.labels[fieldName][prop]){
        return this.labels[fieldName][prop]
      }else{
        return defaultVal;
      }
    },
  }
}
</script>
