<template>
<div>
  <link-item-field
      :field="obj.link"/>
  <v-textarea auto-grow
              label="Texte"
              rows="1"
              v-model="obj.text"
              filled
              counter

  />
  <v-switch v-model="obj.right"
            :label="obj.right?'Aligné à droite':'Aligné à gauche'"
  />
  <photo-list-block-edit
      :block="obj"/>

</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";
import PhotoListBlockEdit from "@/Models/blocks/media/photo-list/photo-list-block-edit";

export default {
  name: "push-photos-block-edit",
  components: {PhotoListBlockEdit, LinkItemField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PushPhotosBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
