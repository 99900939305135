<template>
<div>
    <rich-text-field v-model="obj.text" />
</div>
</template>

<script>
import BlockMixin from "../blocks/BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";

export default {
  name: "core-string-html-block-edit",
  components: {RichTextField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CoreStringBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
