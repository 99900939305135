<template>
<div class="titre-block-edit">
  <v-text-field
    label="Titre"
    v-model="obj.text"
    placeholder="Saisissez un titre"
  />
  <v-row>

    <v-col>
      <v-select
          label="Style de titre"
          v-model="obj.cssClass"
          :items="[
          //{text:'Titre 1',value:'h1'},
          //{text:'Titre 2',value:'h2'},
          {text:'Grand',value:'h3'},
          {text:'Petit',value:'h4'},
      ]"
      ></v-select>
      <v-switch
          v-model="obj.center"
          label="Centrer"
          dense
      />
    </v-col>
    <v-col>
      <v-select
          label="Balise pour le SEO"
          v-model="obj.seo"
          :items="['h1','h2','h3','h4','div']"
      />
      <v-switch
          v-if="!obj.hasLink"
          v-model="obj.arrow"
          label="Afficher la flèche"
          dense
      />

    </v-col>

  </v-row>

  <v-switch
      label="Ajouter un lien à côté ?"
      v-model="obj.hasLink"
      dense
  />

  <link-item-field
      v-if="obj.hasLink"
      label=""
      :field="obj.lien"
  />

  <anchor-form v-if="anchor" :block="block"/>




</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import AnchorForm from "@/Models/blocks/edit/anchor-form";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "titre-block-edit",
  components: {LinkItemField, AnchorForm},
  mixins:[BlockMixin],
  props:{
    anchor:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    /**
     *
     * @return {TitreBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
