<template>
<div>
  <list-items-organizer :list="obj.notifications">
    <list-items-organizer-item
        v-for="notif of obj.notifications.items"
        :list="obj.notifications"
        :item="notif"
        :key="notif.uid">

      <v-text-field
          v-model="notif.texte"
          label="Texte"
      ></v-text-field>
      <link-item-field :field="notif"/>

    </list-items-organizer-item>
  </list-items-organizer>
</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "notifications-block-edit",
  components: {LinkItemField, ListItemsOrganizer, ListItemsOrganizerItem},
  mixins:[BlockMixin],
  mounted() {
    if(this.obj.notifications.items.length===0){
        this.obj.notifications.append();
        this.obj.notifications.append();
    }
  },
  computed:{
    /**
     *
     * @return {NotificationsBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
