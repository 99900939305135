<template>
  <div>
    <v-text-field
        filled
        label="Nom"
        placeholder="..."
        v-model="recordObject.name"
    />
    <blocks-field
        label="Données"
        display-blocks-names
        :allow-edit-structure="$dbUi.preferences.debugMode"
        :field="recordObject.data"/>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";

export default {
  name: "page-edit",
  components: {
    BlocksField,
     CodePreview
  },
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {OpendataModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

