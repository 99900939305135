<template>
  <div>

    <page-edit-fields :record="record"
                      :labels="{
        name:{
          label:'Nom de la formation',
          placeholder:'Mon super article'
        }
      }
      "
    />
    <v-text-field
        filled
        label="Baseline"
        v-model="recordObject.baseline"
    />
    <v-row>
      <v-col>
        <v-text-field
            filled
            label="Durée"
            v-model="recordObject.duree"
            placeholder="99 ans"
        />
      </v-col>
      <v-col>
        <v-text-field
            filled
            label="Rythme"
            v-model="recordObject.rythme"
            placeholder="Une journée par semaine"
        />
      </v-col>
    </v-row>

    <blocks-field
        label="Blocks de contenu"
        :field="recordObject.blocks"/>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";

export default {
  name: "formation-edit",
  components: {PageEditFields, BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  },computed:{
    /**
     *
     * @return {FormationModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

