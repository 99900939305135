<template>
<div class="records-field">
  <field-label
      v-if="label"
      class="mb-2"
  >{{label}} *</field-label>
  <v-autocomplete
      v-model="selectedRecord"
      @change="onSelectRecord"
      :placeholder="placeholder"
      :items="possibleRecords"
      :filter="customFilter"
      filled
      item-text="name"
      return-object
      hide-details
  >
    <template v-slot:prepend-inner>
      <!-- permet par exemple de placer un drapeau ;) -->
      <slot name="prepend"></slot>
    </template>

    <template v-slot:append>
      <slot name="action"></slot>
    </template>

    <!-- le record sélectionné -->
    <template v-slot:selection="data">
      <record-list-item :record="data.item" class="ml-n3"/>
    </template>

    <!-- Liste des fichiers -->
    <template v-slot:item="data">
      <record-list-item-avatar :record="data.item"/>
      <record-list-item-content :record="data.item"/>
      <record-list-item-action :record="data.item"/>
    </template>

  </v-autocomplete>

  <v-list>
    <record-list-item v-for="r of f.records"
                      :key="r.uid"
                      :record="r">
      <template slot="action">
        <v-icon small @click="f.up(r)">mdi-arrow-up</v-icon>
        <v-icon small @click="f.down(r)">mdi-arrow-down</v-icon>
        <v-icon small @click="f.remove(r)" class="ml-2">mdi-trash-can-outline</v-icon>
      </template>
    </record-list-item>
  </v-list>

</div>
</template>

<script>
import RecordListItem from "../records/record-list-item";
import FieldLabel from "./field-label";
import RecordListItemAvatar from "../records/list-item/record-list-item-avatar";
import RecordListItemContent from "../records/list-item/record-list-item-content";
import RecordListItemAction from "../records/list-item/record-list-item-action";
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
export default {
  name: "records-field",
  mixins:[RecordFieldMixin],
  components: {
    RecordListItemAction,
    RecordListItemContent,
    RecordListItemAvatar,
    FieldLabel,
    RecordListItem,
  },
  data(){return{
    selectedRecord:null
  }},
  methods:{
    onSelectRecord(){
      console.log("select record")
      this.f.addRecord(this.selectedRecord);
      let me=this;
      setTimeout(function(){
        me.selectedRecord=null;
      },200);

    }
  },
  computed:{
    /**
     * Le champ recordS
     * @return {RecordsField}
     */
    f(){
      return this.field;
    },
  }
}
</script>
