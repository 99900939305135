<template>
<div class="mb-4">
    <image-field label=""
                  placeholder="Page en lien"
                  :field="f.image"
                  :record-types="[]"
                  read-only
    />
</div>
</template>

<script>
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import ImageField from "@/ee09/db-ui/fields/image-field";
export default {
  name: "image-item-field",
  mixins:[RecordFieldMixin],
  components: {
    ImageField,
  },
  data(){return{}},
  computed:{
    /**
     * Le champ ImageItem
     * @return {ImageItem}
     */
    f(){
      return this.field;
    },
  }
}
</script>
