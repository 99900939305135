<template>
  <div class="rich-text-field">

    <div v-if="editor" class="mb-2">
      <v-btn  text icon tile
              @click="editor.chain().focus().toggleBold().run()"
              :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn text icon tile
              @click="editor.chain().focus().toggleItalic().run()"
              :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn text icon tile
              @click="setLink"
              :class="{ 'is-active': editor.isActive('link') }">
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn text icon tile
             @click="editor.chain().focus().toggleBulletList().run()"
             :class="{ 'is-active': editor.isActive('bulletList') }">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn text icon tile
             @click="editor.chain().focus().setHardBreak().run()">
        <v-icon>mdi-keyboard-return</v-icon>
      </v-btn>
      <!--
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        strike
      </button>
      <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </button>
      -->
      <v-btn  text icon tile
              @click="editor.chain().focus().unsetAllMarks().clearNodes().run();">
        <v-icon>mdi-format-clear</v-icon>
      </v-btn>
      <!--
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>

      <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        h1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        h2
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        h4
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </button>


      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        ordered list
      </button>
      <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        code block
      </button>
      -->
      <!--
      <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>

      <button @click="editor.chain().focus().undo().run()">
        undo
      </button>
      <button @click="editor.chain().focus().redo().run()">
        redo
      </button>
      -->
    </div>
    <editor-content class="editor-box mb-5" :editor="editor"/>

  </div>
</template>


<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import { defaultExtensions } from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link'
export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value
      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()
      if (isSame) {
        return
      }
      this.editor.commands.setContent(this.value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
          Link.configure({
            openOnClick:false
          }),
          ...defaultExtensions()
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
  methods:{
    setLink() {
      const url = window.prompt("Adresse du lien (http://...)");
      if(url){
        this.editor.chain().focus().setLink({ href: url }).run();
      }else{
        this.editor.chain().focus().unsetLink().run();
      }

    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
};
</script>
<style lang="less">
.rich-text-field{
  .editor-box> * {
    background-color: #F0F0F0;
    padding: 15px;
    max-height: 80vh;
    overflow-y: auto;
    color: #000;
  }

  .is-active{
    color: #000 !important;
    background-color: #E0E0E0 !important;
  }
  /* *:focus {
     outline: none;
  }  */
}

</style>