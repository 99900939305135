<template>
  <div>

    <page-edit-fields :record="record"
                      :labels="{
        name:{
          label:'Métier',
          placeholder:'Architecte, designer, etc...'
        }
      }
      "
    />

    <blocks-field
        label="Blocks de contenu"
        :field="recordObject.blocks"/>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";

export default {
  name: "metier-edit",
  components: {PageEditFields, BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  },computed:{
    /**
     *
     * @return {MetierModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

