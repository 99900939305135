<template>
  <div>

    <page-edit-fields :record="record"
      :labels="{
        name:{
          label:'Titre',
          placeholder:'Mon super article'
        }
      }
      "
    />


    <v-text-field
        filled
        label="Sous titre"
        v-model="rec.baseline"
        placeholder="Ici complétez le titre de l'article"
    />

    <date-field
        label="Date de publication"
        :field="rec.date_published"
    />

    <blocks-field
        label="Blocks de contenu"
        :field="rec.blocks"/>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />


  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import DateField from "@/ee09/db-ui/fields/date-field";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";


export default {
  name: "post-edit",
  components: {PageEditFields, BlocksField, DateField, CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {PostModel}
     */
    rec(){
      return this.record;
    }
  }
}
</script>

