var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Type de poster","items":[
                  {
                    text:'Normal',
                    value:'poster-marge',
                  },
                  {
                    text:'Pleine largeur',
                    value:'poster-full-width',
                  },
                  {
                    text:'Home',
                    value:'poster-home',
                  } ]},model:{value:(_vm.recordObject.extravars.posterDisplay),callback:function ($$v) {_vm.$set(_vm.recordObject.extravars, "posterDisplay", $$v)},expression:"recordObject.extravars.posterDisplay"}})],1),_c('v-col',[(_vm.formationsPossible)?_c('v-switch',{attrs:{"dense":"","label":"Menu formations"},model:{value:(_vm.recordObject.extravars.posterMenuFormations),callback:function ($$v) {_vm.$set(_vm.recordObject.extravars, "posterMenuFormations", $$v)},expression:"recordObject.extravars.posterMenuFormations"}}):_vm._e()],1)],1),(_vm.notificationsPossible)?_c('v-textarea',{attrs:{"label":"Texte du poster","filled":"","auto-grow":""},model:{value:(_vm.recordObject.extravars.posterText),callback:function ($$v) {_vm.$set(_vm.recordObject.extravars, "posterText", $$v)},expression:"recordObject.extravars.posterText"}}):_vm._e(),(_vm.notificationsPossible)?_c('div',[_c('field-label',[_vm._v("Notifications du poster")]),_c('notifications-block-edit',{attrs:{"block":_vm.recordObject.moredata.getByName('posterNotifications')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }