<template>
<div class="mb-4">
  <v-row>
    <v-col>
      <field-label
          v-if="label"
          class="mb-2"
      >{{label}}</field-label>
      <v-text-field
          label="Texte affiché sur le lien"
          v-model="f.label"
      />
    </v-col>
    <v-col>
      <v-switch
          v-model="f.external"
          label="Lien externe ?"
          dense
      />
    </v-col>
  </v-row>


  <v-text-field
      v-if="f.external"
      label="Url externe"
      v-model="f.url"
      :error-messages="f.errors"
  />
  <div v-if="!f.external">
    <record-field label=""
                  placeholder="Page en lien"
                  :field="f.pageRecord"
                  :record-types="[]"
    />
  </div>
</div>
</template>

<script>
import FieldLabel from "./field-label";
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import RecordField from "@/ee09/db-ui/fields/record-field";
export default {
  name: "link-item-field",
  mixins:[RecordFieldMixin],
  components: {
    RecordField,
    FieldLabel,
  },
  data(){return{}},
  computed:{
    /**
     * Le champ LinkItem
     * @return {LinkItem}
     */
    f(){
      return this.field;
    },
  }
}
</script>
