<template>
<div class="titre-block-edit">
  <!--
  <v-textarea
    label="Texte"
    v-model="obj.text"
    placeholder="Saisissez votre texte"
     auto-grow
  />
  -->
  <v-row>
    <v-col cols="12">
      <v-select
          label="Taille de texte"
          v-model="obj.size"
          :items="[
          {
            text:'Normal',
            value:'',
          },
          {
            text:'Petite annotation',
            value:'annotation',
          },
      ]"
          :error-messages="obj.warning"
          :hide-details="!obj.warning"
      />
    </v-col>
    <v-col
        v-if="obj.size!=='annotation'"
        cols="auto">
      <v-switch
          v-model="obj.framed"
          label="Encart"
      />
    </v-col>
    <v-col>
      <v-switch
          v-model="obj.twoCols"
          label="Deux colonnes"
      />
    </v-col>
  </v-row>

  <v-text-field v-if="obj.framed"
                v-model="obj.title"
                label="Titre de l'encart"
                hide-details
  />

  <anchor-form v-if="obj.framed"
               :block="block"
                class="mb-2"
  />

  <field-label v-if="obj.twoCols">1ère colonne</field-label>
  <rich-text-field v-model="obj.text" />
  <field-label v-if="obj.twoCols">2ème colonne</field-label>
  <rich-text-field v-if="obj.twoCols" v-model="obj.text2" />



</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import AnchorForm from "@/Models/blocks/edit/anchor-form";
import FieldLabel from "@/ee09/db-ui/fields/field-label";


export default {
  name: "paragraph-block-edit",
  components: {FieldLabel, AnchorForm, RichTextField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {ParagraphBlock}
     */
    obj(){
      return this.block;
    },
  },
}
</script>
