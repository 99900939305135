<template>
<div>
  <v-text-field
    v-model="obj.title"
    />
  <link-item-field
      :field="obj.link"/>
  <v-text-field
      v-model="obj.subtitle"
      label="Sous titre"
  />
  <image-field
      :field="obj.customImage"
      label="Image alternative"/>
  <v-switch
      label="Utiliser la vidéo"
      v-if="obj.hasVideo"
      dense
      v-model="obj.displayVideo"/>
</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";
import ImageField from "@/ee09/db-ui/fields/image-field";

export default {
  name: "push-block-edit",
  components: {ImageField, LinkItemField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PushBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
