<template>
<div class="titre-block-edit">
  <v-textarea
    style="font-family: monospace;font-size: 11px;line-height: 1.5;"
    label="iframe"
    filled
    v-model="obj.userInput"
    placeholder="Adresse Vimeo, Youtube ou code <iframe/>"
    auto-grow
    :persistent-hint="error!==''"
    :error="error!==''"
    :error-messages="error"
  />
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";

export default {
  name: "iframe-block-edit",
  components: {},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {IframeBlock}
     */
    obj(){
      return this.block;
    },
    error(){
      if(!this.obj.userInput){
        return "veuillez saisir un code iframe ou une url"
      }
      if(!this.obj.iframeSrc){
        return "Ce code ne semble pas valide"
      }
      return "";
    }
  }
}
</script>
