<template>
<div class="formation-cycle-block-edit">
  <v-text-field
    label="Cycle"
    v-model="obj.text"
    placeholder="Mise à niveau ou Mastère, etc..."
  />
  <v-row>
    <v-col>
      <v-select
          label="Balise pour le SEO"
          v-model="obj.seo"
          :items="['h1','h2','h3','h4','div']"
      ></v-select>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-text-field
          filled
          label="Durée"
          v-model="blc.duree"
          placeholder="99 ans"
      />
    </v-col>
    <v-col>
      <v-text-field
          filled
          label="Rythme"
          v-model="blc.rythme"
          placeholder="Une journée par semaine"
      />
    </v-col>
  </v-row>
  <!--
  <anchor-form :block="block"/>
  -->



</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
//import AnchorForm from "@/Models/blocks/edit/anchor-form";

export default {
  name: "formation-cycle-block-edit",
  components: {
    //AnchorForm
  },
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {FormationCycleBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
