var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titre-block-edit"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Taille de texte","items":[
          {
            text:'Normal',
            value:'',
          },
          {
            text:'Petite annotation',
            value:'annotation',
          } ],"error-messages":_vm.obj.warning,"hide-details":!_vm.obj.warning},model:{value:(_vm.obj.size),callback:function ($$v) {_vm.$set(_vm.obj, "size", $$v)},expression:"obj.size"}})],1),(_vm.obj.size!=='annotation')?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":"Encart"},model:{value:(_vm.obj.framed),callback:function ($$v) {_vm.$set(_vm.obj, "framed", $$v)},expression:"obj.framed"}})],1):_vm._e(),_c('v-col',[_c('v-switch',{attrs:{"label":"Deux colonnes"},model:{value:(_vm.obj.twoCols),callback:function ($$v) {_vm.$set(_vm.obj, "twoCols", $$v)},expression:"obj.twoCols"}})],1)],1),(_vm.obj.framed)?_c('v-text-field',{attrs:{"label":"Titre de l'encart","hide-details":""},model:{value:(_vm.obj.title),callback:function ($$v) {_vm.$set(_vm.obj, "title", $$v)},expression:"obj.title"}}):_vm._e(),(_vm.obj.framed)?_c('anchor-form',{staticClass:"mb-2",attrs:{"block":_vm.block}}):_vm._e(),(_vm.obj.twoCols)?_c('field-label',[_vm._v("1ère colonne")]):_vm._e(),_c('rich-text-field',{model:{value:(_vm.obj.text),callback:function ($$v) {_vm.$set(_vm.obj, "text", $$v)},expression:"obj.text"}}),(_vm.obj.twoCols)?_c('field-label',[_vm._v("2ème colonne")]):_vm._e(),(_vm.obj.twoCols)?_c('rich-text-field',{model:{value:(_vm.obj.text2),callback:function ($$v) {_vm.$set(_vm.obj, "text2", $$v)},expression:"obj.text2"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }