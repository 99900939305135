<template>
<div class="file-field">
  <v-system-bar lights-out>
  <field-label v-if="label">{{label}}</field-label>
  </v-system-bar>
  <v-autocomplete
      v-model="f.record"
      :items="possibleFiles"
      :filter="customFilter"
      filled
      item-text="name"
      return-object

  >
    <template v-slot:prepend-inner>
      <!-- permet par exemple de placer un drapeau ;) -->
      <slot name="prepend"></slot>
    </template>

    <template v-slot:append>
      <slot name="action"></slot>
      <div @mousedown.stop="">
        <record-create-btn
            small
            record-type="filerecord"
            :file-accept="fileAccept"
            @set-record-file="setFile"
            :progress="progress"
            :status="status"
        />
      </div>
    </template>

    <!-- le record sélectionné -->
    <template v-slot:selection="data">
      <record-list-item :record="data.item" class="ml-n3"/>
    </template>

    <!-- Liste des fichiers -->
    <template v-slot:item="data">
      <record-list-item-avatar :record="data.item"/>
      <record-list-item-content :record="data.item"/>
      <record-list-item-action :record="data.item"/>
    </template>

  </v-autocomplete>

</div>
</template>

<script>

import FieldLabel from "@/ee09/db-ui/fields/field-label";
import RecordListItemAction from "@/ee09/db-ui/records/list-item/record-list-item-action";
import RecordListItemAvatar from "@/ee09/db-ui/records/list-item/record-list-item-avatar";
import RecordListItemContent from "@/ee09/db-ui/records/list-item/record-list-item-content";
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import RecordCreateBtn from "@/ee09/db-ui/records/record-create-btn";
export default {
  name: "file-field",
  components: {
    RecordCreateBtn,
    RecordListItem,
    RecordListItemContent,
    RecordListItemAvatar,
    RecordListItemAction,
    FieldLabel,

  },
  props:{
    label:{type:String},
    field:{type:Object},
    fileAccept:{type:String,default: "*"},
  },
  data(){
    return{
      progress:0,
      status:''
    }
  },
  methods:{
    /**
     *
     * @param {event} e
     * @param {FileRecord} record
     */
    setFile(e,record){
      console.log("setFile",record)
      let me = this;
      if(record){
        me.field.record=record;
      }
    },
    customFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1;
    }
  },
  computed:{
    /**
     * Le champ image
     * @return {FileField}
     */
    f(){
      return this.field;
    },
    possibleFiles(){
      return window.$db.recordListFiles.filter(
          f => this.$db.utils.file.validateFileFormat(this.fileAccept,f.mime,f.extension)
      )
    }
  }
}
</script>
