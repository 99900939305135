<template>
  <div>
    <page-edit-fields :record="record"/>
    <poster-options :record="record" />
    <blocks-field
        label="Blocks de contenu"
        :field="recordObject.blocks"/>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import PosterOptions from "@/Models/edit-shared/poster-options";


export default {
  name: "page-edit",
  components: {
    PosterOptions,
    PageEditFields,
    BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  },computed:{
    /**
     *
     * @return {PageModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

