<template>
<div>
  <v-text-field
      label="Titre (optionnel)"
      v-model="obj.titre"
      filledzzz dense
      placeholder="Agenda, chiffres clé..."
      auto-grow
  />
  <v-select label="Type d'infographies"
            filledzz
            v-model="obj.infographieType"
            :items="[
        { text: 'Pourcentages', value: 'pourcentage' },
        { text: 'Chiffres', value: 'chiffre' },
        { text: 'Images', value: 'image' }
      ]"
  />
  <list-items-organizer :list="obj.infos">
    <list-items-organizer-item
        v-for="info of obj.infos.items"
        :list="obj.infos"
        :item="info"
        :key="info.uid">
      <v-slider
          v-if="obj.infographieType==='pourcentage'"
          v-model="info.pourcentage"
          :label="`${info.pourcentage}%`"
          dense
          :thumb-size="24"
          thumb-label
      ></v-slider>
      <v-text-field
          v-if="obj.infographieType==='chiffre'"
          v-model="info.chiffre"
          dense
          label="Chiffre"
          type="number"
      ></v-text-field>

      <image-field
          v-if="obj.infographieType==='image'"
          :field="info.image"/>

      <rich-text-field v-model="info.texte"/>
    </list-items-organizer-item>
  </list-items-organizer>

</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ImageField from "@/ee09/db-ui/fields/image-field";

export default {
  name: "infographies-block-edit",
  components: {ImageField, ListItemsOrganizerItem, ListItemsOrganizer, RichTextField},
  mixins:[BlockMixin],
  data () {
    return {}
  },
  mounted() {
    if(this.obj.infos.items.length===0){
      this.obj.infos.prepend();
      this.obj.infos.prepend();
      this.obj.infos.prepend();
    }
  },
  computed:{
    /**
     *
     * @return {InfographiesBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
