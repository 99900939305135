<template>
<div class="objectif-block-edit">
  <rich-text-field v-model="obj.text" />
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";

export default {
  name: "objectif-block-edit",
  components: {RichTextField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {ObjectifBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
