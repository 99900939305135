<template>
  <div>

    <page-edit-fields :record="record"
                      :labels="{
        name:{
          label:'Nom du projet',
          placeholder:'Mon super projet'
        }
      }
      "
    />
    <v-text-field
        filled
        label="Sous titre"
        v-model="recordObject.baseline"
        placeholder="Ici complétez le titre du projet"
    />

    <date-field
        label="Date du projet"
        :field="recordObject.date_published"
    />

    <blocks-field
        label="Blocks de contenu"
        :field="recordObject.blocks"/>


    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import DateField from "@/ee09/db-ui/fields/date-field";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";

export default {
  name: "projet-edit",
  components: {PageEditFields, BlocksField, DateField, CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {ProjetModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

