<template>
  <v-row>
    <v-col cols="12">
      <v-switch
          v-model="block.isAnchor"
          label="Utiliser comme ancre de page"
          hide-details
          dense
      />
    </v-col>
    <v-col v-if="block.isAnchor">
      <v-text-field
          label="Texte sur l'ancre"
          v-model="block.anchorName"
          placeholder="Saisissez un nom d'ancre"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "anchor-form",
  mixins:[BlockMixin],
}
</script>

<style scoped>

</style>